<template>
    <div class="Title">
        <img  @click="toWeb()"  class="title_logo" src="../../assets//images/CwLogo.svg" alt="">
      <div class="title_icons">
        <div class="menu_icon" @click="changeLanguage(languageTxt)">
            <img class="languageIcon" src="../../assets/images/LanguageIcon.svg" alt="">
            {{ languageTxt == 'zh' ?'CN':'EN'  }}
            
        </div>
        <div class="menu_icon" @click="goHelp()" v-if="!mobileFlag">
            <img src="../../assets/images/HelpIcon.svg" alt="">
            {{ $t('title_help') }}
        </div>
        <div class="menu_icon" @click="goMessage()" v-if="!mobileFlag">
            <img src="../../assets/images/MessageIcon.svg" alt="">
            {{ $t('title_message') }}
        </div>
        <div class="menu_icon" @click="personClick()" ref="personIcon">
            <img src="../../assets/images/PersonIcon.svg" alt="">
        </div>
        <div class="menu_icon"  ref="personIcon" v-if="mobileFlag" @click="menuClick()">
            <img src="../../assets/images/MobileMenu.svg" alt="">
        </div>
      </div>
      <div class="personCard" v-show="isPerson" ref="perosonCard">
        <img src="../../assets/images/PersonImage.svg" alt="">
        <p class="personName">
            {{ userInfo.email}}
        </p>
        <div class="personReset" @click="isPopupVisible = true">
            <div class="personReset_left">
                <img src="../../assets/images/PersonLock.svg" alt="">
                <span>
                    {{ $t('personResetTxt') }}
                </span>
            </div>
            <div class="personReset_right">
                <span>
                    {{ $t('MyFollowers_Reset') }}
                </span>
                <i class="el-icon-arrow-right"></i>
            </div>

        </div>
        <p class="logout" @click="logout()">
            {{ $t('logout') }}
        </p>
      </div>
      <el-dialog class="popupMoudule resetPassword" width="324px" :visible.sync="isPopupVisible" center :close-on-click-modal="false" :show-close="false" >
            <div slot="title">
                <img src="../../assets/images/ResetPasswordIcon.svg" alt="">
                <p class="popupMouduleTitle">
                    {{ $t('resetPasswordTitle') }}
                </p>
            </div>
            <el-form   class="forgetForm" ref="changeForm" :rules="rules" label-position="top" label-width="80px" :model="changeForm">
                <el-form-item :label="$t('login_password')" prop="password" >
                    <el-input v-model="changeForm.password" autocomplete="off"  type="password" show-password></el-input>
                </el-form-item>
                <el-form-item :label="$t('login_confirmpassword')" prop="confirm"  >
                    <el-input v-model="changeForm.confirm"  autocomplete="off"  type="password" show-password ></el-input>
                </el-form-item>
            </el-form>
            
            <span slot="footer" class="btnReset" >
                <el-button class="cancelGray" type="primary" @click="cancelPassword()">{{ $t('MyFollowers_Cancel') }}</el-button>
                <el-button class="confirmBlue" type="primary" v-loading.fullscreen.lock="fullscreenLoading"  @click="forget()">{{ $t('MyFollowers_confirm') }}</el-button>
            </span>
            
        </el-dialog>
        <el-dialog class="resetPassword" width="324px" :visible.sync="isPopupVisibleTips" :show-close="false" center :close-on-click-modal="false"  >
            <div slot="title">
                <img src="../../assets/images/NotifySuccess.svg" alt="">
                <p class="popupMouduleTitle">
                    {{ $t('resetSuccess') }}
                </p>
                <p  class="popupMouduleContent">{{ $t('resetSuccessContent') }}</p>
            </div>
            
            <span slot="footer" class="btnOne" >
                <el-button type="primary" class="confirmBlue"  @click="isPopupVisibleTips = false,isPopupVisible = false">{{ $t('MyFollowers_confirm') }}</el-button>
            </span>
        </el-dialog>
        <div v-if="isMenu" class="mask" @click="isMenu = false">
        
        </div>
        <transition name="fade"> 
                <Menu id="menuCard" v-show="isMenu" />
        
            
        </transition>

        
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
    import { mapState } from "vuex";
    import Menu from '@/components/common/Menu.vue'
    import Bus from '../../plugins/bus'

  
  export default {
    name: 'Title',
    components: {
        Menu
    },
    computed: {
      ...mapState(["userInfo","mobileFlag","lang"]),
    },
    data() {
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('login_passwordNotNull')));
            } else {
                var pwdRegex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,16}');
                if(!pwdRegex.test(value)){
                    callback(new Error(this.$t('login_passwordNum')));
                }else if (this.changeForm.confirm !== '') {
                    this.$refs.changeForm.validateField('confirm');
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('login_password2NotNull')));
            } else if (value !== this.changeForm.password) {
                callback(new Error(this.$t('login_password2Notcorrect')));
            } else {
                callback();
            }
        };
        return {
            languageTxt: "",
            isPerson:false,
            isPopupVisible:false,
            changeForm:{
                password:'',
                confirm:''
            },
            rules: {
                password: [
                    { validator: validatePass, trigger: 'blur' }
                ],
                confirm: [
                    { validator: validatePass2, trigger: 'blur' }
                ],
            },
            isPopupVisibleTips:false,
            fullscreenLoading:false,
            isMenu:false
        };
    },
    mounted() {
        Bus.$on('isMobileMenu', myMsg => {  
            this.isMenu = myMsg
        });  
    },
    created(){
        this.languageTxt =  this.lang

        this.screenClick()
    },
    methods: {
        changeLanguage(tag){
            
            if(tag == 'zh'){
                this.$i18n.locale = "en";
	            // localStorage.setItem('localeLanguage', lang)
                this.$store.commit("setLang",'en');
                this.languageTxt = 'en'
                Bus.$emit('isLanguage', true);
            }else{
                this.$i18n.locale = "zh";
                this.$store.commit("setLang",'zh');
                this.languageTxt = 'zh'
                Bus.$emit('isLanguage', true);
            }
        },
        goHelp(){
            this.$router.push({ path: '/HelpCenter'});
        },
        goMessage(){
            this.$router.push({ path: '/MyMessage'});
        },
        screenClick() {
            document.addEventListener("click", e => {
                if (this.$refs.perosonCard) {
                    let self = this.$refs.perosonCard.contains(e.target);
                    let seft = this.$refs.personIcon.contains(e.target);
                    if (!self && !seft) {
                        this.isPerson = false;
                    }
                }
                
                
            })
    
        },
        logout(){
            localStorage.clear()
            sessionStorage.clear()
            this.$router.push({ path: '/Login'});
        },
        forget(){
            this.$refs.changeForm.validate((valid) => {
                if (valid) {
                    this.fullscreenLoading = true
                    this.changeForm.email = this.userInfo.email
                    let params = {
                        confirm: this.Rsa.encrypt(this.changeForm.confirm.trim()),
                        email: this.userInfo.email,
                        password: this.Rsa.encrypt(this.changeForm.password.trim()),
                    }
                    this.$axios
                    .post("/Api/Login/UpdatePassword", params)
                    .then((res) => {
                        let dt = res.data;
                        if (dt.isSuccess) {
                            // this.$refs.changeForm.resetFields();
                            this.changeForm.password = ''
                            this.changeForm.confirm = ''
                            this.isPopupVisibleTips = true
                        } else {
                            this.$notify.error({title: this.$t('MyFollowers_prompt'), message:dt.result});
                        }
                    })
                    .finally(() => {
                        this.fullscreenLoading = false;
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        personClick(){
            if(!this.mobileFlag){
                this.isPerson = !this.isPerson
            }else{
                this.$router.push({ path: '/MyPerson'});
                this.isMenu = false
                Bus.$emit('isMask', this.isMenu);

            }
        },
        menuClick(){
            this.isMenu = !this.isMenu
            Bus.$emit('isMask', this.isMenu);
        },
        cancelPassword(){
            this.$refs.changeForm.resetFields();
            this.isPopupVisible = false
        },
        toWeb(){
            window.open("https://www.copy-wealth.com");
        }
    }
  }
  </script>
  <style lang="less" scoped>
  .Title{
    width: 100%;
    height: 60px;
    background: #FFF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .title_logo{
        padding-left: 40px;
        cursor: pointer;
    }
    .title_icons{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        margin-right: 20px;
        .menu_icon{
            margin: 0 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            color: #0B205D;
            font-family: 'PingFangSC-Medium';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
           
            cursor: pointer;
            img{
                margin-right: 6px;
                width:22px;
                height:22px;
            }
        }
    }
    .personCard{
        position: absolute;
        top: 80px;
        right: 40px;
        padding: 24px 10px;
        border-radius: 4px;
        background: #FFF;
        box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.10);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 99;
        .personName{
            color: #0B205D;
            font-family: 'PingFangSC-Medium';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin: 5px 0 20px 0;
        }
        .personReset{
            min-width:calc(235px - 20px) ;
            border-radius: 4px;
            background: #F8F8F8;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 9px 10px;
            align-items: center;
            cursor: pointer;
            .personReset_left{
                display: flex;
                flex-direction: row;
                align-items: center;
                color: #0B205D;
                font-family: 'PingFangSC-Medium';
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                img{
                    margin-right: 6px;
                }
            }
            .personReset_right{
                display: flex;
                flex-direction: row;
                align-items: center;
                color: #0B205D;
                font-family: 'PingFangSC-Regular';
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                i{
                    margin-left: 6px;
                }
            }
        }
        .logout{
            margin: 20px 0 0 0;
            color: #0B205D;
            font-family: 'PingFangSC-Regular';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            cursor: pointer;
        }
    }
  }
  .resetPassword{
    .popupMouduleTitle{
        color:  #333;
        text-align: center;
        font-family: 'PingFangSC-Medium';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 20px auto;
    }
    .btnReset{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        
    }
  }
  .popupMouduleTitle{
    color:  #333;
    text-align: center;
    font-family: 'PingFangSC-Semibold';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .popupMouduleContent{
    color:  #333;
text-align: center;
font-family: PingFang SC;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin: 20px auto;
  }
  .cancelGray,.confirmBlue{
            border-radius: 4px;
            background:  #E5E5E5;
            color:  #333;
            font-family: 'PingFangSC-Regular';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            border: 0;
            padding: 10px 30px;

        }
        .confirmBlue{
            background: #4D71FF;
            color: #FFF;
        }
@media screen and (max-width: 767px) {
    .Title{
        min-height: 0;
        height: 42px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;
        .title_logo{
            margin-left: 16px;
            height: 20px;
            min-width: 110px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 0;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .title_icons{
            margin-right: 0;
            .menu_icon{
                margin-right: 20px;
                margin-left: 0;
                img{
                    width: 20px;
                    height: 20px;
                    margin-right: 0;
                }
                .languageIcon{
                    margin-right: 6px;
                }
            }
        }

    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .35s
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
        opacity: 0
    } 
    .mask{
        position: fixed;
        opacity: 0.7;
        background: #FFF;
        width: 100%;
        height: calc(100% - 46px) ;
        top: 46px;
    }
}
  </style>
  