<template>
  <div class="home">
    <Title />
    <div class="mobileTitle" v-if="mobileFlag">
      {{  $route.path == '/FollowEcharts'? $route.query.account + $t('echarts_xtitle') :($route.path == '/MyAccount'? checkPortfolio.portfolioName + $t('MyFollowers_xSignalSource'):$t(mobileTitle)) }}
    </div>
    <div class="contentWrap">
      
      <Menu />
      <div class="content">
        
        <router-view />
      </div>
    </div>
    
    <!-- <div v-if="mobileFlag" class="mobileBottom">
      <span>Provided by</span>  <b>|</b> <img  src="@/assets/images/FollowLogo.svg" alt="" />
    </div> -->
  </div>
</template>

<script>
// @ is an alias to /src
import Menu from '@/components/common/Menu.vue'
import Language from '@/components/common/Language.vue'
import Title from '@/components/common/Title.vue'

import { mapState } from "vuex";

export default {
  name: 'Home',
  components: {
    Menu,
    Language,
    Title
  },
  data() {
    return {
      mobileTitle: '',
      isMask:false
    };
  },
  computed: {
    ...mapState(["checkPortfolio","mobileFlag"]),
  },
  watch: {
    '$route':'getTitle',

  },
  created(){
    this.getTitle()
  },
  methods:{
    getTitle(){
      this.$router.options.routes[5].children.forEach((item,index) => {
          if(item.path == this.$route.path){
            this.mobileTitle = item.nameTxt
            // if(item.path == '/FollowEcharts'){
            //   this.mobileTitle = this.$route.query.account + this.$t('echarts_xtitle') 
            // }
            // if(item.path == '/MyAccount'){
            //   this.mobileTitle = this.checkPortfolio.portfolioName + this.$t('MyFollowers_xSignalSource')
            // }
          }
      });  
    }
  }
}
</script>
<style lang="less" scoped>
.contentWrap{
  display: flex;
  flex-direction: row;
  height: calc(100% - 60px);
}
@media screen and (max-width: 767px) {
  .contentWrap{
  display: flex;
  flex-direction: row;
  height: calc(100% - 84px);
}
.mobileBottom{
  position: fixed;
  height: 46px;
  bottom: 0;
  background: #000000;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 999;
  span{
    display: inline-block;
    margin-left: 20px;
    line-height: 46px;
    font-size: 14px;
    color: #FFFFFF;
  }
  b{
    display: inline-block;
    margin: 0 10px;
    color: #313131;
    height: 20px;
  }
  img{
    height: 20px;
  }
}
  .mobileTitle{
    // padding-bottom: 50px;
    position: fixed;
    height: 42px;
    top: 42px;
    width: 100%;
    line-height: 42px;
    font-size: 15px;
    color: #0B205D;
    text-align: center;
  }
  .mask{
    opacity: 0.7;
    background: #FFF;
  }
}
</style>
